html, body, #root, .app, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
    background: #FFFFFF;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    color: #000;
    overflow: hidden;
}

p {
    margin: unset;
}

body > iframe {
    display: none;
}

.main_content.reports_ui {
    display: flex;
    min-height: 100%;
    flex: 1 1;
}

body.background .content_div > .side_bar + div {
    background: #FFFFFF;
    border-radius: 20px;
    margin-right: 20px;
    padding: 10px 50px 30px;
    min-height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.side_bar_content {
    padding: 15px 15px 0;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

body.background .content_div > .side_bar + div {
    background: #141414;
    border-radius: 15px;
    margin-right: 20px;
    padding: 10px 50px 30px;
    min-height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.content_div .router_content {
    background: #ECF8E9;
    border-radius: 10px;
}

.scroll_bar {
    overflow: auto;
}

/* scroll bar */
html::-webkit-scrollbar,
body::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 4px;
    background-clip: content-box;
    background-color: #757575;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
